import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import RefleshIcon from '../assets/refleshIcon'
import { QueryClient } from 'react-query';



const Card = ({ label, value, refetch }) => {

    const [isSmallScreen] = useMediaQuery('(max-width:750px)');

    return (
        <Flex flexDirection={'column'} width={isSmallScreen ? '100%' : '30%'} height={isSmallScreen ? '300px' : '90%'} marginBottom={isSmallScreen ? 5 : 0} backgroundColor={'#fff'} boxShadow={'xl'} borderRadius={'xl'} padding={5}>
            <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                <Text fontSize={20} fontWeight={'bold'}>
                    {label}
                </Text>
            </Flex>
            <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
                <Text fontSize={23} fontWeight={'bold'}>{value} 명</Text>
            </Flex>
        </Flex>
    )
}

export default Card