import { Box, Button, Center, Flex, Input, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/media-query';

const Sign = () => {

    const [verifyValue, setVerifyValue] = useState('');
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery('(max-width:750px)');

    const onSubmit = () => {
        if (verifyValue === 'tbx_promotion') {
            navigate('/home');
        }
    }

    useEffect(() => {
        console.log(isSmallScreen);
    }, [isSmallScreen])

    return (
        <Flex
            width={'100%'}
            height={'100%'}
            position={'fixed'}
            background={'linear-gradient(35deg, #BF091B, #E2494F)'}
            top={0}
            zIndex={999}
            left={0}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Flex w={isSmallScreen ? '90%' : '40%'} height={'30%'} backgroundColor={'#fff'} borderRadius={'xl'} flexDirection={'column'} padding={5}>
                <Center>
                    <Text fontSize={25} fontWeight={'bold'}>Verify</Text>
                </Center>
                <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
                    <Input
                        placeholder='Enter Verify Code'
                        onChange={(e) => setVerifyValue(e.target.value)} ////
                        value={verifyValue}
                    />
                </Flex>
                <Button colorScheme='whatsapp' onClick={onSubmit}>
                    제출
                </Button>
            </Flex>
        </Flex>
    )
}

export default Sign