import axios from "axios"

export const getEventCount = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_participation_count`);

    return data;
}

export const getCountryLank = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_country_participation_count`);

    return data;
}

export const getAirdropAllUser = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASEURL}/endpoints/event_second_airdrop_participation`);

    return data;
}