import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery } from '@chakra-ui/react'
import { createColumnHelper, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect } from 'react'
import LeftDoubleIcon from '../assets/LeftDoubleIcon';
import LeftIcon from '../assets/LeftIcon';
import RightIcon from '../assets/RightIcon';
import RightDoubleIcon from '../assets/RightDoubleIcon';

const CountryTable = ({ tableData }) => {

    const columnHelper = createColumnHelper();
    const [isSmallScreen] = useMediaQuery('(max-width:750px)');
    const columns = [
        columnHelper.accessor("index", {
            header: "순위", cell: (props) =>
                <>{Number(props.row.id) + 1}</>
        }),
        columnHelper.accessor("country", { header: "국가" }),
        columnHelper.accessor("count", { header: "신청 횟수" }),
    ]


    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    useEffect(() => {
        if (table) {
            table.setPageSize(5);
        }
    }, [table, table.getPageCount()])

    return (
        <Flex flexDirection={'column'} overflow={'scroll'} width={isSmallScreen ? '100%' : '30%'} height={'95%'} backgroundColor={'#fff'} boxShadow={'xl'} borderRadius={'xl'} padding={5} sx={{
            "::-webkit-scrollbar": {
                display: "none",
            },
        }}>
            <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'} mb={5}>
                <Text fontSize={20} fontWeight={'bold'}>
                    참여 국가 순위
                </Text>
            </Flex>
            <TableContainer width={'100%'} flex={1} >
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id} width={'25%'}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id} width={'25%'}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id} fontSize={13}  >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex width={'100%'} justifyContent={'flex-end'} alignItems={'center'} height={'60px'}>
                <Button onClick={() => table.setPageIndex(0)} padding={0}>
                    <LeftDoubleIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => {
                    table.previousPage()
                }}>
                    <LeftIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => {
                    if (table.getCanNextPage()) {
                        table.nextPage()
                    }
                }}>
                    <RightIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                    <RightDoubleIcon />
                </Button>
            </Flex>
        </Flex>
    )
}

export default CountryTable