import React from 'react'

const RightDoubleIcon = ({
    backColor = "#000"
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={backColor} viewBox="0 0 256 256">
            <path d="M144.49,136.49l-80,80a12,12,0,0,1-17-17L119,128,47.51,56.49a12,12,0,0,1,17-17l80,80A12,12,0,0,1,144.49,136.49Zm80-17-80-80a12,12,0,1,0-17,17L199,128l-71.52,71.51a12,12,0,0,0,17,17l80-80A12,12,0,0,0,224.49,119.51Z"></path>
        </svg>
    )
}

export default RightDoubleIcon