import React from 'react'

const LeftDoubleIcon = ({
    backColor = "#000"
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={backColor} viewBox="0 0 256 256">
            <path d="M208.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L137,128ZM57,128l71.52-71.51a12,12,0,0,0-17-17l-80,80a12,12,0,0,0,0,17l80,80a12,12,0,0,0,17-17Z"></path>
        </svg>
    )
}

export default LeftDoubleIcon