import { createColumnHelper, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect } from 'react'
import user_list from '../dummy/userList.json';
import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery } from '@chakra-ui/react';
import moment from 'moment';
import LeftDoubleIcon from '../assets/LeftDoubleIcon';
import LeftIcon from '../assets/LeftIcon';
import RightIcon from '../assets/RightIcon';
import RightDoubleIcon from '../assets/RightDoubleIcon';

const EventUserTable = ({ userList }) => {

    const columnHelper = createColumnHelper();
    const [isSmallScreen] = useMediaQuery('(max-width:750px)');
    const columns = [
        columnHelper.accessor("email", { header: "Email" }),
        columnHelper.accessor("phone_number", { header: "Phone" }),
        columnHelper.accessor("amount_drawn", { header: "Drawn" }),
        columnHelper.accessor("date_created", {
            header: "Join Time", cell: props =>
                <>{moment(props.getValue()).format('YYYY/MM/DD hh:mm')}</>
        }),
    ]

    const table = useReactTable({
        data: userList,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    useEffect(() => {
        if (table) {
            table.setPageSize(10);
        }
    }, [table, table.getPageCount()])


    return (
        <Flex flexDirection={'column'} overflow={'scroll'} width={'100%'} height={'fit-content'} backgroundColor={'#fff'} boxShadow={'xl'} borderRadius={'xl'} padding={5} sx={{
            "::-webkit-scrollbar": {
                display: "none",
            },
        }}>
            <TableContainer width={'100%'} flex={1} >
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id} width={'25%'}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id} width={'25%'}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id} fontSize={13}  >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex justifyContent={'flex-end'} marginTop={5}>
                <Text fontSize={15}>
                    {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                </Text>
            </Flex>
            <Flex width={'100%'} justifyContent={'flex-end'} alignItems={'center'} height={'80px'}>
                <Button onClick={() => table.setPageIndex(0)} padding={0}>
                    <LeftDoubleIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => {
                    table.previousPage()
                }}>
                    <LeftIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => {
                    if (table.getCanNextPage()) {
                        table.nextPage()
                    }
                }}>
                    <RightIcon />
                </Button>
                <Button marginLeft={3} padding={0} onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                    <RightDoubleIcon />
                </Button>
            </Flex>
        </Flex>
    )
}

export default EventUserTable