import React from 'react'

const LeftIcon = ({
    backColor = '#000'
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={backColor} viewBox="0 0 256 256"><path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"></path></svg>
    )
}

export default LeftIcon