import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Sign from './components/Sign';

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Sign />} />
        <Route path='/home' element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
