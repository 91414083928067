import React from 'react'
import { QueryClient, useMutation, useQueries, useQuery } from 'react-query'
import { Box, Button, Center, Container, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import Card from '../components/Card';
import { getAirdropAllUser, getCountryLank, getEventCount } from '../api/api';
import EventUserTable from '../components/EventUserTable';
import CountryTable from '../components/CountryTable';
import { useEffect } from 'react';
import RefleshIcon from '../assets/refleshIcon';
import Sign from '../components/Sign';

const Home = () => {

    const [isSmallScreen] = useMediaQuery('(max-width:750px)');

    const {
        isLoading: eventCountLoading,
        data: eventCountData,
        refetch: refetchEventCount,
        isFetching: eventCountRefetching
    } = useQuery(["event_count"], getEventCount);

    const {
        data: countryLankData,
        isLoading: countLankLoading,
        refetch: refetchCountryLank,
        isFetching: countLankRefetching
    } = useQuery(["country_lank"], getCountryLank);

    const {
        data: airdropUserList,
        isLoading: userListLoading,
        refetch: refetchAirdropUserList,
        isRefetching: userListRefetching
    } = useQuery(["airdrop_all_user"], getAirdropAllUser);

    const refetchQueries = () => {
        console.log('refetch');
        refetchEventCount();
        refetchCountryLank();
        refetchAirdropUserList();
    };

    if (eventCountLoading || countLankLoading || userListLoading || eventCountRefetching || countLankRefetching || userListRefetching) {
        return (
            <Center width={'100%'} height={'100vh'} padding={30} background={'linear-gradient(35deg, #BF091B, #E2494F)'}>
                <Text color={'#fff'} fontSize={20}>Loading</Text>
            </Center>
        )
    }
    return (
        <Box width={'100%'} height={'fit-content'} padding={30} background={'linear-gradient(35deg, #BF091B, #E2494F)'}>
            <Flex flexDirection={'column'} backgroundColor={'#fff'} width={'100%'} height={'100%'} borderRadius={'xl'} padding={5}>
                <Flex width={'100%'} height={'10%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize={isSmallScreen ? 15 : 30} fontWeight={'bold'}>
                        TrustBridgeX 프로모션 현황
                    </Text>
                    <Button colorScheme='telegram' onClick={() => refetchQueries()}>
                        <RefleshIcon backColor='#fff' />
                    </Button>
                </Flex>
                <Flex flex={1} flexDirection={'column'} marginTop={isSmallScreen ? 5 : 0}>
                    <Flex width={'100%'} flexDirection={isSmallScreen ? 'column' : 'row'} height={isSmallScreen ? 'fit-content' : '500px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Card label={'메인 이벤트 참여 횟수'} value={eventCountData?.results.count_event_first_airdrop} />
                        <Card label={'텔레그램 참여 횟수'} value={eventCountData?.results.count_event_telegram ?? '0'} />
                        {
                            !countLankLoading &&
                            <CountryTable tableData={countryLankData?.results} />
                        }
                    </Flex>
                    <Flex width={'100%'} minHeight={'800px'} justifyContent={'space-around'} alignItems={'center'}>
                        {
                            !userListLoading &&
                            <EventUserTable userList={airdropUserList?.results} />
                        }
                        {/* <Card /> */}
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Home